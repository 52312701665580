/**
 * Created by Administrator on 2019/10/7.
 */
import { request } from '../utils/request'
import serviceInterface from '../api/serviceInterface'


/**
 * 获取用户充值历史
 * @param params
 * @returns {*|Promise<unknown>|undefined}
 */
export const getChargeList = (params) => {
    return request({
        url: serviceInterface.recharge.cradChargeList,
        params: params
    })
}


/**
 * 根据用户信息进行充值
 * @param params
 * @returns {*|Promise<unknown>|undefined}
 */
export const doCharge = (params) => {
    return request({
        url: serviceInterface.recharge.cradCharge,
        params: params
    })
}

/**
 * 根据用户信息进行充值
 * @param params
 * @returns {*|Promise<unknown>|undefined}
 */
export const paySuccess = (params) => {
    return request({
        url: serviceInterface.recharge.paySuccess,
        params: params
    })
}

/**
 * 根据用户信息进行vip开通
 * @param params
 * @returns {*|Promise<unknown>|undefined}
 */
export const vipPaySuccess = (params) => {
    return request({
        url: serviceInterface.recharge.vipPaySuccess,
        params: params
    })
}

/**
 * vip 充值记录
 * @param params
 * @returns {*|Promise<unknown>|undefined}
 */
export const vipChargeList = (params) => {
    return request({
        url: serviceInterface.recharge.vipChargeList,
        params: params
    })
}

/**
 * 根据用户余额进行vip开通
 * @param params
 * @returns {*|Promise<unknown>|undefined}
 */
export const vipYePaySuccess = (params) => {
    return request({
        url: serviceInterface.recharge.vipYePaySuccess,
        params: params
    })
}
/**
 * 根据获取验证码
 * @param params
 * @returns {*|Promise<unknown>|undefined}
 */
export const getImageCode = (params) => {
    return request({
        url: serviceInterface.recharge.cardImage,
        params: params,
        method:'get'
    })
}
